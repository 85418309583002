<template>
  <div class="popup-terms-conditions">
    <div class="popup-terms-conditions__wrapper">
      <p class="popup-terms-conditions__p">Текст отзыва не может содержать:</p>
      <ul class="popup-terms-conditions__ul">
        <li class="popup-terms-conditions__li">нецензурную лексику либо ее производные;</li>
        <li class="popup-terms-conditions__li">информацию, направленную на пропаганду ненависти и
          дискриминации по расовому, этническому, половому, языковому, религиозному, социальному,
          политическому признакам;
        </li>
        <li class="popup-terms-conditions__li">ущемление прав национальных меньшинств,
          несовершеннолетних, призывы к причинению им или их имуществу вреда (ущерба) в любой форме;
        </li>
        <li class="popup-terms-conditions__li">обвинения и (или) оскорбления в адрес других
          пользователей, органов государственной власти и местного самоуправления, иных лиц;
        </li>
        <li class="popup-terms-conditions__li">угрозы действиями, которые способны нанести вред и
          (или) ущерб другим пользователям, гостям, органам власти, органам местного самоуправления,
          организациям, иным лицам;
        </li>
        <li class="popup-terms-conditions__li">информацию, распространяемую в коммерческих либо в
          любых других целях, отличных от целей создания и функционирования сервиса.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupTermsConditions',
};
</script>
